.cantRateCheckbox {
    place-content: start !important;
    margin-top: 18px;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
